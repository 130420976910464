<template>
  <span class="flex align-center">
    <a-button v-if="filters.length > 0" class="txt-orange" type="link" icon="close-circle" @click="clearFilters">
      {{ $t('components.description.clearAllFilters') }}
    </a-button>
  </span>
</template>

<script>
import { buildArrayOfValues } from '../../../util/util';

export default {
  name: 'TableRowColumnControls',
  props: {
    hot: {
      type: Object,
      required: false,
      default: () => undefined
    },
    disabled: {
      type: Boolean,
      required: false
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.$root.$on('clearAllDataEntryFilters', () => this.clearFilters(false));
  },
  methods: {
    clearFilters(event) {
      const filtersPlugin = this.hot.getPlugin('filters');
      if (filtersPlugin) {
        filtersPlugin.clearConditions();
        filtersPlugin.filter();
      }
      // if function is called outside of @click event, do not emit event
      if (event) this.$root.$emit('clearAllDataEntryFilters');
    },
    addRow() {
      const selected = this.hot.getSelected();
      if (selected && selected.length > 0) {
        const [startRow, startCol, endRow] = selected[0];
        this.hot.alter('insert_row', endRow);
      } else {
        this.hot.alter('insert_row', 0);
      }
    },
    removeRow() {
      const selected = this.hot.getSelected();
      if (selected && selected.length > 0) {
        selected.forEach(selection => {
          const [startRow, startCol, endRow] = selection;
          const rows = buildArrayOfValues(startRow, endRow);
          const changes = rows.map(row => {
            const data = this.hot.getData(row, 0).flat();
            if (data && data[0] === null) {
              this.hot.alter('remove_row', startRow);
              return [];
            }
            if (data && data[0]) {
              return [row, 0, null, !!data[0]];
            }
            return [row, 0, null, false];
          }).filter(change => change.length > 0);
          // We update the first column to trigger the row in the database to delete
          this.hot.setDataAtCell(changes);
        });
      }
    }
  }
};
</script>

<style lang="scss">
</style>
